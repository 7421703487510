import React, { useContext, useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreement1B() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    const inputVal = e.target.value.toUpperCase()
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value.toUpperCase(),
    })

    keyboard.current.setInput(inputVal)
    saveUser({
      [e.target.name]: e.target.value.toUpperCase(),
    })
  }

  useEffect(() => {
    if (inputName === "substitute_contact_number") {
      setLayoutName("numeric")
    } else {
      setLayoutName("default")
    }
  }, [inputName])

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="dt-flex flex-column m-4">
        <h1 className="display-5 font-weight-bold">Appendix 1</h1>

        <h3 className="font-weight-bold ml-4">
          Acknowledgement of Substitution (pt 2)
        </h3>

        <p className="lead mt-4">
          To: Coordinator-in-charge, ALPHAVIBE
          <br />
          I, the undersigned Substitute, acknowledge that I will be collecting
          the fees for work done directly from the Contractor,{" "}
          <strong>{user.name}</strong>.
          <br />I understand and acknowledge that ALPHAVIBE is not obliged,
          liable, and/or responsible for the fees to be collected from the
          Contractor who is as stated above.
        </p>

        <h4>Substitute's Details</h4>

        <table
          style={{ transform: "scale(1.5)", marginLeft: 160, marginTop: 50 }}
        >
          <tbody>
            <tr>
              <td className="p-2">
                <sup>Substitute's Name: (in FULL & BLOCK LETTERS):</sup>
                <br />
                <input
                  type="text"
                  name="substitute_name"
                  onFocus={() => setInputName("substitute_name")}
                  autoComplete="off"
                  value={user.substitute_name}
                  onChange={e => saveField(e)}
                  style={{
                    width: "100%",
                  }}
                />
              </td>
              <td className="p-2">
                <sup>Substitute's Contractor Code:</sup>
                <br />
                <input
                  type="text"
                  name="substitute_contractor_code"
                  onFocus={() => setInputName("substitute_contractor_code")}
                  autoComplete="off"
                  value={user.substitute_contractor_code}
                  onChange={e => saveField(e)}
                  style={{
                    width: "100%",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="p-2">
                <sup>Substitute's Contact Number:</sup>
                <br />
                <input
                  type="text"
                  name="substitute_contact_number"
                  onFocus={() => setInputName("substitute_contact_number")}
                  autoComplete="off"
                  value={user.substitute_contact_number}
                  onChange={e => saveField(e)}
                  style={{
                    width: "100%",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleKeyboard
            isActive={user.isKeyboardActive}
            onClick={() => {
              saveUser({
                ...user,
                isKeyboardActive: !user.isKeyboardActive,
              })
            }}
          />
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>
        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => {
              saveDraftUser(user)
              navigate("/contractor-agreement-2a")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-1a")}
          />
        </div>
        {user.isKeyboardActive && (
          <div
            className="fixed-bottom w-50 p-4"
            style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} Q W E R T Y U I O P [ ] \\",
                  "{lock} A S D F G H J K L ; ' {enter}",
                  "{shift} Z X C V B N M , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        )}
      </div>
    </Background>
  )
}
